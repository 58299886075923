import PageNotFound from '@/components/pageNotFound/pageNotFound';
import { useAnalytics } from 'framework';
import { AnalyticsCategory, AnalyticsPageName } from 'legacy-features';
import { useEffect } from 'react';

export default function NotFound() {
  const { analytics } = useAnalytics();

  useEffect(() => {
    analytics.page(AnalyticsCategory.error, { name: AnalyticsPageName.notFound }, { statusCode: '404' });
  }, [analytics]);

  return <PageNotFound />;
}
